  .vertical-timeline-element-content {
    /* box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important; */
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(224, 45, 45, 0.15) !important;
    padding: 2em 3em !important;
  }

  .vertical-timeline-element {
    margin-bottom: -16px; /* Reduce space between timeline elements */
}
  .vertical-timeline-element-content {
}

  .vertical-timeline {
      --line-color: #3498db; /* Replace with your desired color */
  }


  .date {
    color: rgb(6, 141, 151);
  }
  
  #description {
    margin: 1.5em 0 2em 0;
  }
  
  .button {
    text-decoration: none;
    padding: 0.5em 1em;
    display: flex;
    border-radius: 5px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    justify-content: center;
    color: white;
    height: 40px;
    width: 200px;
  }


  
  .workButton {
    background-color: #d606d2;
  }
  
  .workButton:hover {
    background-color: #c50a0a;
  }
  
  .schoolButton {
    background-color: #f9c74f;
  }
  
  .schoolButton:hover {
    background-color: #f3bc3c;
  }

  
  .centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.centered-image {
    height: 120px;
    width: 140px;
    margin-bottom: 1rem;
}
